@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css");

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$md: 768px;
$sm: 576px;
$lg: 992px;

$footer-color: #aeb5bc;
$active-color: #3458cc;
$nav-color: #3e4857;
$hr-color: #3e4348;
$nav-bg-color: #f8f9fc;
$nav-border-color: #f4f4f4;
$search-color: #1e4780;
$login-head-color: #1e4780;
$login-input-br: #1e4780;
$login-input-place: #1e4780;
$login-link: #1e4780;
$login-btn: #1e4780;
$login-btn-c: #fff;
$login-btn-o: #1e4780;
$login-btn-o-c: #1e4780;
$login-check: #1e4780;
$blog-box-writer: #467f8b;
$blog-box-writer-hd: #0707bf;
$blog-box-more: #0707bf;
$page-bar-br: #e2e2e3;
$pagination-item-br: #e2e2e3;
$pagination-item-ac: #1e4780;
$pagination-item-ac-c: #fff;
$grey: #eee;

.modal-dialog {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  overflow-x: hidden;
}

.b {
  font-weight: 500;
}

em {
  font-style: italic !important;
}

strong {
  font-weight: bolder !important;
}

.mobile-hide {
  @media (max-width: $lg) {
    display: none;
  }
}

.nav-mobile {
  display: flex;
  flex-direction: column;
}

.mobile-show {
  display: none;
  @media (max-width: $lg) {
    display: flex;
  }
}

.color-app {
  color: #004892;
}

.search-main {
  position: relative;

  .search-result {
    width: 500px;
    @media (max-width: $sm) {
      width: calc(100% - 40px);
    }
    background: transparent;
    max-height: 300px;
    height: max-content;
    overflow-y: auto;
    border-radius: 6px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: 500ms opacity, 500ms height;

    .advantage {
      display: block;
      background: white;
      color: initial;
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      cursor: pointer;

      &:hover {
        background: $nav-bg-color;
      }

      span {
        font-size: 14px;
      }
    }
  }
  .search-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-top: 80px;
    padding-bottom: 50px;
    @media (max-width: $sm) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .search-modal-back {
      position: fixed;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;
      cursor: pointer;
      @media (max-width: $sm) {
        background-color: transparent;
      }
    }
    .search-modal-body {
      transition: all 1s ease-in-out;
      position: relative;
      background-color: #fff;
      width: 500px;
      z-index: 2;
      border-radius: 25px;
      opacity: 1;
      overflow: hidden;
      margin: auto;
      display: flex;
      flex-direction: column;
      height: 100%;
      @media (max-width: $sm) {
        width: 100%;

        border-radius: 0px;
      }
      .search-modal-title {
        position: relative;
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #ddd;
        .search-modal-head {
          color: $search-color;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
        }

        .search-modal-close {
          position: absolute;
          left: 0px;
          top: 0px;
          padding: 0px 20px;
          height: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: $search-color;
            display: block;
            margin-bottom: -3px;
            font-size: 18px;
          }
          i.times {
            display: block;
            @media (max-width: $sm) {
              display: none;
            }
          }
          i.arrow {
            display: none;
            @media (max-width: $sm) {
              display: block;
            }
          }
        }
      }
      .search-modal-input {
        width: 100%;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          min-width: 50px;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
        }
        input {
          flex: 1;
          height: 40px;
          background-color: transparent;
          border: none;
          outline: none;
          padding: 0px 5px;
        }
      }
      .search-modal-content {
        padding: 25px 15px;
        overflow: auto;
        flex: 1;
        .search-status {
          color: #fff;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 500;
          padding: 5px 10px;
          display: inline-block;
        }
      }
    }
  }
}

.main {
  & > .container {
    min-height: 50vh;
  }

  margin-top: -60px;
  margin-bottom: -50px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url("../images/arkaplan.png");
  background-size: cover;
  background-repeat: no-repeat;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

a:hover {
  text-decoration: none;
}

.root {
  width: 100%;
  height: 100%;
}

nav {
  background: $nav-bg-color;
  max-height: auto;
  min-height: 100px;
  border-bottom: 3px solid $nav-border-color;
  border-bottom-left-radius: 40%;
  border-bottom-right-radius: 40%;
  z-index: 2;
  z-index: 999;

  @media (max-width: $sm) {
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }

  .navbar-brand {
    margin-left: 120px;

    @media (max-width: $md) {
      margin-left: 25px;
    }

    .logo-img {
      width: 100px;
    }
  }

  .navbar-collapse {
    justify-content: center;
padding-right: 100px;
@media (max-width:991px) {
  padding-right: 0px;
}
    &.show {
      padding-bottom: 20px;
    }

    .nav-item {
      margin: 10px;

      .nav-link {
        text-transform: uppercase;
        letter-spacing: 0.6px;
        color: $nav-color;
        transition: 300ms color;
        text-align: center;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          color: $active-color !important;
        }

        &:focus {
          color: $nav-color;
        }
      }

      .dropdown-item {
        color: $nav-color;
        transition: 300ms color, 300ms background-color;

        &:active {
          background-color: $active-color;
          color: white;
        }
      }

      .show,
      .active {
        color: $active-color !important;
      }
    }

    .nav-btn {
      margin: auto auto auto 3rem;

      .nav-login {
        padding: 10px;
        background: $active-color;
        border-radius: 6px;
        text-transform: none !important;
        color: white !important;

        &:hover {
          color: white !important;
        }
      }
    }
  }
}

.location {
  text-align: center;
  position: relative;
  width: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: -15px;
  background: $nav-bg-color;
  text-transform: uppercase;
  color: $search-color;
  border: 2px solid $nav-border-color;
  border-radius: 3px;
  z-index: 999;
  text-align: center;
  padding: 5px;
}

.search-content {
  position: relative;
  height: fit-content;
  width: 500px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: $sm) {
    width: 100%;
    padding: 0 20px;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;

    @media (max-width: $sm) {
      left: 30px;
    }

    i {
      color: $search-color;
    }
  }

  .search {
    background: transparent;
    border: 2px solid $search-color;
    border-radius: 6px;
    height: 30px;
    width: 500px;
    color: $search-color;
    padding: 0 10px 0 30px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    @media (max-width: $sm) {
      width: 100%;
    }
  }
}

.slider {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &.home-slider {
    margin-bottom: -200px;
  }

  @media (max-width: $lg) {
    padding: 0 20px;
  }
  @media (max-width: $md) {
    width: 100%;
  }
  @media (max-width: $sm) {
    width: 100%;
  }

  .carousel {
    width: 700px;
    border-radius: 20px;

    .carousel-inner {
      width: 100%;
      height: 100%;

      .carousel-item {
        width: 100%;
        height: 100%;

        .carousel-img {
          background-size: contain !important;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 400px;
          @media (max-width: $md) {
            height: 300px;
          }
          @media (max-width: $sm) {
            height: 200px;
          }
        }
      }
    }
  }
}

.content {
  border-top: 3px solid $nav-border-color;
  //z-index: 1;
  background: white;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  &.home-content {
    padding-top: calc(4rem + 150px);
  }

  .container {
    .button-group {
      margin-top: 2rem;
      margin-bottom: 1rem;

      & > div {
        margin-bottom: 1rem;

        &.sana-ozel {
          a {
            color: #6abbe1;
          }
        }

        &.duyurular {
          a {
            color: #8dc48f;
          }
        }

        &.indirimler {
          a {
            color: #8dc48f;
          }
        }
      }

      a {
        /*-webkit-box-shadow: -5px 3px 12px 0px rgba(0, 0, 0, 0.13);
-moz-box-shadow: -5px 3px 12px 0px rgba(0, 0, 0, 0.13);
box-shadow: -5px 3px 12px 0px rgba(0, 0, 0, 0.13);
*/
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 10px 40px 10px;
        font-weight: bolder;
        height: 100%;

        img {
          @media (max-width: $sm) {
            width: 70px;
          }
          width: 96px;
        }

        span {
          margin-top: 0.8rem;
          text-transform: uppercase;
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .video-group {
      //margin-bottom: 10rem;

      & > div {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-weight: bold;
          margin-top: 0.8rem;
          text-transform: uppercase;
          color: #184284;
          letter-spacing: -0.3px;
        }

        .embed-responsive {
          border-radius: 6px;
        }
      }
    }
  }
}

footer {
  background: #212940;
  color: $footer-color;
  z-index: 2;

  hr {
    border-color: $hr-color;
  }

  h5 {
    font-size: 18px;
  }

  .f-nav {
    margin-bottom: 10px;
  }

  .social-media {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    a {
      padding: 10px;
      border: 1px solid $footer-color;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 300ms border;

      &:hover {
        border: 1px solid $active-color;

        i {
          color: $active-color;
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      i {
        color: $footer-color;
        transition: 300ms color;
      }
    }
  }

  .navigation {
    li {
      padding-bottom: 10px;

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      a {
        color: $footer-color;
        transition: 300ms color;

        &:hover {
          color: $active-color;
        }

        &:before {
          font-family: "FontAwesome";
          content: "\f054";
          padding-right: 0.8rem;
          font-size: 10px;
        }
      }
    }
  }

  form {
    margin-top: 1rem;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0;

    label {
      font-weight: bolder;
      margin-bottom: 0.7rem;

      &.required {
        &:after {
          content: "*";
          color: #d44d56;
          padding-left: 2px;
        }
      }
    }

    .input {
      &.email {
        position: relative;

        &:before {
          font-family: "FontAwesome";
          content: "\f003";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1rem;
        }

        input {
          padding: 0 10px 0 3rem;
        }
      }
    }

    textarea,
    input {
      width: 100%;
      border-radius: 6px;
      background-color: #29324e;
      border: 0;
      color: #727987;
      padding: 0 10px 0 10px;

      &::placeholder {
        color: #727987;
      }
    }

    textarea {
      padding: 10px;
      resize: none;
    }

    input {
      height: 40px;
    }

    button {
      height: 40px;
      background: #26325c;
      border: 1px solid #283972;
      border-radius: 6px;
      color: $footer-color;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);
      -moz-box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);
      box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);

      &:focus {
        outline: none;
      }
    }
  }

  .footer-copyright {
    position: relative;
    margin-top: 1rem;
    border-top: 1px solid $hr-color;

    a:first-of-type {
      color: $footer-color;
      transition: 300ms color;

      &:hover {
        color: $active-color;
      }
    }

    .goToTop {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2rem;
      height: 32px;
      width: 32px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      background: #26325c;
      border: 1px solid #283972;
      border-radius: 6px;
      color: #3458cc;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);
      -moz-box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);
      box-shadow: 0px 0px 10px 0px rgba(40, 57, 114, 0.5);
    }
  }
}

/*Login*/

.login-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;

  .login-wrapper {
    position: absolute;
    right: 0px;
    top: 0px;
    height: auto;
    min-height: 100%;
    width: 50%;
    display: flex;
    justify-content: stretch;
    align-items: center;

    @media (max-width: $md) {
      width: 100%;
      align-items: flex-start;
    }
    @media (max-width: $sm) {
      width: 100%;
      align-items: flex-start;
    }

    .login-form {
      width: 400px;
      margin: auto;

      padding: 50px 0;

      @media (max-width: $sm) {
        padding: 50px 25px;
      }
      @media (max-width: $md) {
        padding: 50px 25px;
      }

      .login-head {
        width: 100%;
        font-size: 40px;
        margin-bottom: 100px;
        text-align: center;
        color: $login-head-color;
      }

      .login-form-group {
        position: relative;
        width: 100%;

        .login-input {
          width: 100%;
          height: 45px;
          border: 0;
          outline: 0;
          border-radius: 5px;
          border: 2px solid $login-input-br;
          padding: 0 50px;

          @media (max-width: $sm) {
            width: 100%;
          }
          @media (max-width: $md) {
            width: 100%;
          }
        }

        .login-input::placeholder {
          text-align: center;
          color: $login-input-place;
        }

        .login-input-icon {
          position: absolute;
          left: 10px;
          top: 7.5px;
          width: 30px;
          height: 30px;
          background-size: contain;
        }

        .login-check {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-start;

          &.login-check-spuare {
            .login-check-helper {
              border-radius: 5px;

              &::before {
                border-radius: 3px;
              }
            }
          }

          input[type="checkbox"] {
            position: absolute;
            transform: scale(0);
          }

          .login-check-helper {
            position: relative;
            border-radius: 100%;
            min-height: 25px;
            min-width: 25px;
            max-height: 25px;
            max-width: 25px;
            cursor: pointer;
            border: 2px solid $login-check;

            &::before {
              content: "";
              position: absolute;
              left: 3px;
              top: 3px;
              width: 15px;
              height: 15px;
              border-radius: 100%;
              transition-duration: 0.1s;
              transition-property: all;
              transition-timing-function: ease-in-out;
            }
          }

          input[type="checkbox"]:checked + .login-check-helper::before {
            background-color: $login-check;
          }

          .login-check-label {
            margin-left: 20px;
            color: $login-check;
            cursor: pointer;
          }
        }
      }

      .login-link-group {
        margin-top: 100px;
      }

      .login-link {
        display: inline-block;
        color: $login-link;
        margin-top: 40px;
        cursor: pointer;
      }

      .btn-login-group {
        margin: 40px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-login {
          background-color: $login-btn;
          border-radius: 25px;
          padding: 10px 25px;
          font-weight: 500;
          color: $login-btn-c;
        }

        .btn-login-o {
          border: 2px solid $login-btn-o;
          border-radius: 25px;
          padding: 10px 25px;
          font-weight: 500;
          color: $login-btn-o-c;
        }
      }
    }
  }

  .login-thumb {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 50%;

    @media (max-width: $sm) {
      display: none;
    }
    @media (max-width: $md) {
      display: none;
    }
  }

  .login-thumb-img {
    position: relative;
    width: 150%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.blog-box {
  padding: 10px;
}

.blog-box-date {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: right;
  padding-bottom: 10px;
}

.blog-box-edit {
  position: absolute;
  right: 5px;
  bottom: 5px;

  button {
    border-radius: 10px;
    width: 35px;
    height: 35px;
    border: 0px;
    outline: 0px;
    background-color: #564999;
    color: #fff;
  }
}

.blog-box-body {
  position: relative;
  background: rgb(43, 182, 174);
  background: linear-gradient(
    90deg,
    rgba(43, 182, 174, 1) 12%,
    rgba(86, 73, 153, 1) 56%
  );
  padding: 6px;
  border-radius: 10px;
  display: block;
}

.blog-box-content {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 25px 10px;
}

.blog-box-img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-box-article {
  padding-left: 20px;
}

.blog-box-head {
  font-weight: 500;
  margin-bottom: 10px;
}

.blog-box-desc {
  font-size: 12px;
}

.blog-box-bottom {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.blog-box-writer {
  font-size: 12px;
  color: $blog-box-writer;
}

.blog-box-writer-hd {
  font-size: 12px;
  font-weight: 500;
  color: $blog-box-writer-hd;
}

.blog-box-more {
  font-size: 12px;
  color: $blog-box-more;
  font-weight: 500;
}

.page-bar {
  border-bottom: 1px solid $page-bar-br;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 5px;

  .page-bar-btn {
    position: relative;
    width: 50px;
    height: 38px;

    .page-bar-btn-back {
      width: 100%;
      height: 100%;
    }

    .page-bar-btn-plus {
      position: absolute;
      left: 11px;
      top: 11px;
      width: 16px;
      height: 16px;
    }
  }

  .page-bar-head {
    margin-left: 10px;
    font-weight: 500;
    color: #000;
  }
}

.pagination {
  width: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .perPage {
    width: auto;
  }
}

.pagination-bar {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 25px 0 0 25px;
}

.pagination-prev {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border: 1px solid $pagination-item-br;
  border-radius: 25px 0 0 25px;
  cursor: pointer;

  &:hover {
    background-color: $pagination-item-br;
    border: 1px solid $pagination-item-br;
  }
}

.pagination-next {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border: 1px solid $pagination-item-br;
  border-radius: 0 25px 25px 0;
  cursor: pointer;

  &:hover {
    background-color: $pagination-item-br;
    border: 1px solid $pagination-item-br;
  }
}

.pagination-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  border: 1px solid $pagination-item-br;
  cursor: pointer;

  &:hover {
    background-color: $pagination-item-br;
    border: 1px solid $pagination-item-br;
  }

  &.active {
    background-color: $pagination-item-ac;
    border: 1px solid $pagination-item-ac;
    color: $pagination-item-ac-c;
  }
}

.area {
  background-color: #fff;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 25px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);

  .area-header {
    width: 100%;
    height: 400px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 25px;
    background-color: $grey;

    @media (max-width: $sm) {
      height: 200px;
    }
    @media (max-width: $md) {
      height: 300px;
    }
  }

  .area-bar {
    width: 100%;
    height: auto;
    padding: 0 50px;
    display: table;
  }

  .area-profile {
    float: left;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background-color: #fff;
    margin-top: -60px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }

  .area-writer {
    float: left;
    padding: 10px;
    padding-left: 20px;
    color: #467f8b;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 500;
      color: #0707bf;
    }
  }

  .area-date {
    float: right;
    padding-top: 10px;
  }

  .area-like {
    float: right;
    padding-top: 10px;

    .btn {
      font-size: 30px;
    }
  }

  .area-head {
    width: 100%;
    height: auto;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }

  .area-body {
    padding: 30px 20px;
    widows: 100%;

    .area-logo {
      height: 150px;
      align-self: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .area-content-img {
      background-size: contain;
      background-position: center;
      border-radius: 30px;
      padding: 0px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .area-comments {
    width: 100%;
    padding: 20px;
    padding-bottom: 50px;

    .area-comment-name {
      font-weight: 500;
      border-bottom: 1px solid #000;
    }

    .area-comment-content {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .area-comment-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;

      .area-comment-date {
        color: #959595;
        margin-right: 20px;
      }

      .area-comment-stars {
        color: #f48901;
        font-size: 25px;
        margin-right: 20px;
      }
    }

    .area-comment-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 20px;
      margin-top: -15px;

      .area-comment-btn {
        .area-comment-btn-icon {
          font-size: 20px;
        }
      }
    }
  }
}

.require {
  color: #e81a46;
}

.commnet-textarea {
  min-height: 150px;
}

.nav-profile {
  position: absolute;
  right: 8px;
  //bottom: -15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;

  @media (max-width: 992px) {
    top: 20px !important;
    right: 100px;
  }

  .nav-profile-frame {
    width: 100%;
    height: 100%;
    background-size: 100%;
    padding: 5px;
  }

  .nav-profile-photo {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
  }
}

.profile-area {
  padding: 20px;
  align-self: stretch;

  .profile-area-body {
    position: relative;
    min-height: 100%;
    background-color: #fff;
    padding: 20px 50px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);

    .profile-area-photo-cont {
      @media (max-width: $sm) {
        width: 100%;
        text-align: center;
      }

      .profile-area-photo {
        display: inline-block;
        width: 160px;
        height: 160px;
        border-radius: 100%;

        .profile-area-photo-frame {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          padding: 18px;
          background-size: 100%;

          .profile-area-photo-img {
            width: 100%;
            height: 100%;
            background-size: cover;
            border-radius: 100%;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .profile-area-name-cont {
      @media (max-width: $sm) {
        width: 100%;
      }

      .profile-area-name {
        font-size: 20px;
        font-weight: 500;
        padding: 15px;
        padding-left: 30px;
        @media (max-width: $sm) {
          margin-bottom: 50px;
          margin-top: 10px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

.editor-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.demo-editor {
  background-color: #fff;
  border: 1px solid #e2e2e3;
  border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
  min-height: 250px;
}

.form-btn {
  background-color: #004892;
  color: #fff;
  padding-left: 100px;
  padding-right: 100px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    color: #fff;
  }
}

.form-area {
  position: relative;
  background-color: #fff;
  padding: 40px;
  border: 1px solid #e2e2e3;
  border-radius: 4px;

  .form-head {
    color: #1e4780;
    font-weight: 500;
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .form-alert {
    position: absolute;
    right: 20px;
    bottom: 20px;
    border: 1px solid #e2e2e3;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);

    .form-alert-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .form-alert-thumb {
      position: relative;
      margin: auto;
      margin-top: 10px;
      width: 50px;
      height: 50px;
      background-size: cover;
    }
  }
}

input.form-input {
  background-color: #fafafa;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  height: 40px;
}

textarea.form-input {
  background-color: #fafafa;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  min-height: 40px;
  padding: 15px;
}

select.form-input {
  background-color: #fafafa;
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  height: 40px;
}

.profile-area-profile-cont {
  @media (max-width: $sm) {
    width: 100%;
    text-align: center;
  }

  .profile-area-profile-big {
    position: relative;
    width: 140px;
    height: 140px;
    min-width: 140px;
    min-height: 140px;
    border-radius: 100%;
  }

  .profile-area-profile-big-img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-color: #e2e2e3;
  }
}

.profile-area-grid {
  position: relative;
}

.profile-area-grid-item {
  height: auto;
  padding: 10px;

  &.active {
    .profile-area-grid-col {
      background-color: #014189;
      color: #fff;

      i {
        color: #fff !important;
      }
    }
  }
}

.profile-area-grid-col {
  display: block;
  color: #000;
  width: 100%;
  height: 100%;
  border: 1px solid #e2e2e3;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background-color: #eeeeee;
  }
}

.profile-area-grid-item-icon {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 35px;
  text-align: center;
}

.profile-area-grid-item-text {
  width: 100%;
  height: auto;
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}

.profile-area-btn-bar {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 1px solid #e2e2e3;
    border-radius: 10px;
    cursor: pointer;
    color: #000;
  }
}

.advantages-bar {
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #e2e2e3;
  padding-bottom: 20px;
}

.advantage-categories {
  @media (max-width: $lg) {
    min-height: auto;
    display: flex;
    margin-bottom: 3rem;
    height: fit-content !important;
  }
  min-height: 70vh;
  overflow-y: auto;

  .advantages-bar-item:nth-child(1) {
    margin-left: 0.9rem;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
    @media (max-width: $lg) {
      height: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 8px;
  }
}

.advantages-bar-item {
  width: auto;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (max-width: $lg) {
    margin-bottom: 0.5rem;
  }

  margin-bottom: 2rem;

  i {
    border: 5px solid #fff;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    margin: auto;
  }

  span {
    display: block;
    text-align: center;
    //padding-top: 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: normal;
    color: #000;
    margin-left: 0.5rem;
    flex: 1;
  }

  button {
    &:hover {
      color: white;
    }
  }

  + .advantages-bar-item {
    margin-left: 15px;
  }
}

.advantage-detail-page {
  position: relative;

  .advantages-menu-item-img-price {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #004892;
    color: #fff;
    padding: 5px 15px;
    font-weight: 500;
    z-index: 100;
  }

  .advantages-menu-item-img-disc {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-weight: 500;
    background-image: url("../images/disc.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
    font-size: 12px;
    z-index: 100;
  }
}

.advantages-menu {
  width: 100%;
  height: max-content;
  margin-left: 1rem;
  @media (max-width: 768px) {
    margin-left: 0;
  }
  @media (max-width: 1209px) {
    margin-left: 0;
  }

  .advantages-text {
    color: #000;
  }

  .advantages-heart {
    font-size: 22px;
    pointer-events: none;
  }

  .advantages-menu-item {
    //padding: 20px;
    margin-bottom: 35px;
    cursor: pointer;
  }

  .advantages-menu-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .advantages-menu-title-img {
      background-size: cover;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 100%;
      background-position: center;
    }

    .advantages-menu-item-title {
      height: auto;
      font-size: 14px;
      color: #004892;
      font-weight: bold;
      padding: 0 15px;
    }
  }

  .advantages-menu-item-content {
    width: 100%;

    .advantages-menu-item-img {
      position: relative;
      margin-top: 15px;
      width: 100%;
      height: 180px;
      background-position: center;
      background-size: cover;
      border-radius: 5px;

      .advantages-menu-item-img-price {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #0048928a;
        color: #fff;
        padding: 5px 15px;
        font-weight: 500;
      }

      .advantages-menu-item-img-disc {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        font-weight: 500;
        background-image: url("../images/disc.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 40px;
        height: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 16px;
        font-size: 12px;
      }
    }

    span,
    i {
      color: #000;
    }
  }
}

.advantages-detail-bar {
  margin: auto;
  width: 100%;
  height: auto;
  padding: 20px;
  text-align: center;

  i {
    color: #004892;
    font-size: 24px;
    cursor: pointer;
  }
}

.advantages-detail-props {
  width: 100%;
  height: auto;

  .advantages-detail-prop {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px;

    span {
      @media (max-width: $sm) {
        font-size: 14px;
      }
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .advantages-detail-prop-item {
      @media (max-width: $sm) {
        width: 70px;
        height: 70px;
      }

      width: 100px;
      height: 100px;
      background-color: #fff;
      border: 3px solid #fff;
      border-radius: 100%;
      padding: 20px;

      .advantages-detail-prop-item-img {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

.advantages-informations {
  width: 100%;
  height: auto;
  flex-wrap: wrap;

  .advantages-information-head {
    font-size: 24px;
    font-weight: 500;
    padding: 0 20px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .advantages-information-logo {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    display: inline-block;
  }

  .advantages-information-sections {
    position: relative;
    width: auto;
    height: auto;

    .advantages-information-item {
      position: relative;
      width: auto;
      height: auto;
      margin-bottom: 10px;

      i {
        font-size: 26px;
        margin-right: 15px;
        width: 26px;
        height: 26px;
      }
    }
  }
}

.advantages-detail-content {
  margin-top: 50px;
  width: 100%;
  height: auto;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);

  .advantages-detail-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .advantages-detail-logo {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
    }

    .advantages-detail-head {
      font-weight: 500;
      font-size: 28px;
      padding-left: 20px;
      color: #014189;
    }
  }

  .advantages-detail-sub-head {
    color: #014189;
    font-size: 22px;
    font-weight: 500;
    padding-top: 20px;
  }

  .advantages-detail-desc {
    padding: 25px 50px;

    @media (max-width: $md) {
      padding: 25px 0;
    }

    .advantages-detail-desc-head {
      color: #014189;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .advantages-detail-casts {
    text-align: center;
    padding: 10px;
    color: #014189;
  }
}

.survey-container {
  width: 100%;
  padding: 100px 0;

  & > .container {
    min-height: 50vh;
  }

  .survey-box {
    .btn-survey {
      background-color: #014189;
      color: #fff;
    }

    .survey-box-head {
      background-color: #fff;
      border-radius: 5px;
      padding: 15px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      font-weight: 500;
    }

    .survey-box-body {
      background-color: #f2f2f2;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      padding: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .survey-box-img-frame {
        width: 110px;
        min-width: 110px;
        min-height: 110px;
        height: 110px;
        background: rgb(43, 182, 174);
        background: linear-gradient(
          90deg,
          rgba(43, 182, 174, 1) 12%,
          rgba(86, 73, 153, 1) 56%
        );
        border-radius: 10px;

        padding: 4px;

        .survey-box-img {
          width: 100%;
          height: 100%;
          background-color: #fff;
          border-radius: 10px;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }

  .survey-accordion-card {
    width: 100%;
    height: auto;

    .survey-accordion-header {
      width: 100%;
      padding: 0px;

      .survey-accordion-btn {
        width: 100%;
        height: 100%;
        border: 0px;
        padding: 10px;
        background-color: $nav-bg-color;
        outline: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .survey-accordion-num {
          position: relative;
          width: 70px;
          min-width: 70px;
          min-height: 70px;
          height: 70px;
          background-color: #5669b8;
          padding: 10px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;

          &::before {
            content: "";
            width: 50px;
            height: 50px;
            background-color: #c9cfe8;
            position: absolute;
            left: 10px;
            top: 10px;
            border-radius: 100%;
          }

          span {
            display: block;
            padding-left: 5px;
            padding-right: 5px;
            z-index: 2;
            border-bottom: 1px solid #fff;
            font-weight: 500;
          }
        }

        .survey-accordion-text {
          margin-left: 20px;
          color: #004892;
          opacity: 1;
        }
      }

      .collapsed {
        .survey-accordion-text {
          opacity: 0.6;
        }
      }
    }

    .survey-question {
      padding: 20px;
      text-align: center;
      color: #004892;
    }

    .survey-answers {
      padding: 20px;

      .survey-answer-option {
        padding: 10px;

        &.active {
          .survey-answer-option-body {
            background-color: #0048925a;
            color: #fff;
          }
        }

        .survey-answer-option-body {
          border-radius: 20px;
          padding: 20px 30px;
          border: 2px solid #00489299;
          cursor: pointer;
        }

        .survey-answer-option-body:hover {
          border-color: #004892;
        }
      }
    }
  }
}

.family-members {
  width: 100%;

  .family-member {
    position: relative;
    width: 100%;
    height: auto;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    .family-member-img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-size: cover;
      background-color: #e2e2e3;
      @media (max-width: $sm) {
        margin: auto;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .family-member-info {
      padding-left: 20px;
      @media (max-width: $sm) {
        width: 100%;
        text-align: center;
      }

      .family-member-name {
        width: 100%;
      }

      .family-member-email {
        width: 100%;
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .family-member-btns {
      position: absolute;
      right: 0px;
      top: 15px;
    }

    + .family-member {
      border-top: 1px solid #e2e2e3;
    }
  }
}

.notifications {
  width: 100%;
  height: auto;

  .notification {
    width: 100%;
    height: auto;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 10px;

    .notification-head {
      width: 100%;
      height: auto;
      font-weight: 500;
    }

    .notification-desc {
      width: 100%;
      height: auto;
      font-size: 12px;
    }

    + .notification {
      margin-top: 10px;
    }
  }
}

.portofilo {
  padding: 10px;
  text-align: center;
  .portofilo-body {
    display: inline-block;
    text-align: center;
    width: 180px;
    .portofilo-img {
      transition: all 0.1s ease-in-out;
      &:hover {
        transform: scale(0.96);
      }
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: 140px;
      height: 140px;
      border-radius: 100%;
      background-color: #fff;
      border: 6px solid #5194d2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .portofilo-play {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0.6;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
      }
    }
    .portofilo-title {
      cursor: pointer;
      width: 100%;
      color: #101b21;
      font-weight: 500;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}
.portofilo-modal {
  .portofilo-modal-diaolg {
    width: 700px;
    max-width: 700px;
    .portofilo-modal-content {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      .portofilo-video {
        width: 100%;
        height: 400px!important;;
      }
    }
    @media (max-width: $md) {
      width: 100%;
      max-width: 100%;

      .portofilo-modal-content {
        .portofilo-video {
          height: 360px!important;;
        }
        border-radius: 0 0 0 0 !important;
      }
    }
    @media (max-width: $sm) {
      width: 100%;
      max-width: calc(100% - 15px);

      .portofilo-modal-content {
        .portofilo-video {
          height: 320px!important;
        }
        border-radius: 10px !important;
      }
    }
  }
}
//

.announcement-header {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #e2e2e3;
}

.announcement {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);

  + .announcement {
    margin-top: 30px;
  }

  .announcement-date {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .announcement-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $md) {
      flex-direction: column;
      justify-content: center;
    }

    .announcement-img {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .announcement-head {
      padding-left: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #004892;

      @media (max-width: $md) {
        margin-top: 15px;
        padding-left: 0px;
        text-align: center;
      }
    }
  }

  .announcement-desc {
    padding: 25px;
    padding-top: 10px;
    padding-left: 80px;
    @media (max-width: $md) {
      padding-left: 0px;
      text-align: center;
    }

    .announcement-more {
      margin-top: 20px;
      color: #004892;
      font-weight: 500;
      font-size: 14px;
      display: block;
    }
  }
}

.favorite-heart {
  color: #e81a46 !important;
}

.contact-accordion {
  widows: 100%;
  overflow: visible;

  .card {
    overflow: visible;
    background-color: transparent;
    border: 0px;

    .card-header {
      width: 100%;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border-bottom: 0px;
      padding: 0px;
      border-radius: 10px;

      button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 20px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0px;
        background-color: transparent;
        outline: 0px;

        .head {
          color: #014189;
          font-weight: 500;
          flex: 1;
        }

        .icon {
          font-size: 40px;
          color: #c1c3c6;
          height: 100%;
          width: 50px;
          min-width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: -25px;
        }

        &[aria-expanded="true"] {
          .icon {
            transform: rotate(-180deg);
          }
        }
      }
    }

    + .card {
      margin-top: 60px;
      align-items: center;
    }

    .contact-desc {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .contact-exp {
      font-weight: 600;
      color: #cbc6c7;
      margin-right: 60px;
    }

    .contact-btn-true {
      background-color: transparent;
      border: 1px solid #104a8e;
      color: #104a8e;
      outline: 0;
      margin-right: 20px;
      padding: 10px 20px;
      border-radius: 10px;
      font-weight: 500;

      &:hover {
        background-color: #104a8e;
        color: #fff;
      }
    }

    .contact-btn-false {
      background-color: transparent;
      border: 1px solid #cbc6c7;
      color: #cbc6c7;
      outline: 0;
      padding: 10px 20px;
      border-radius: 10px;
      font-weight: 500;

      &:hover {
        background-color: #cbc6c7;
        color: #fff;
      }
    }
  }
}

.contact-link {
  display: block;
  width: 100%;
  color: #014189;
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  .contact-link-icon {
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
}

.avantage-info {
  background-color: #004892;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.avantage-code {
  color: #004892;
  font-weight: 500;
}

.contact-us-wrapper {
  width: 100%;
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;

  .contact-us-form {
    width: 100%;
    height: a;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #eee;
    padding: 30px;

    .require {
      color: #ff2424;
    }
  }

  .contact-us-infos {
    padding: 10px;

    .contact-to {
      display: block;
      width: 100%;
      color: #004892;

      i {
        color: #000;
        width: 25px;
        text-align: center;
      }
    }
  }

  .contact-us-complate {
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .contact-us-tick {
      margin-top: 30px;
      width: 50px;
      height: 50px;
    }
  }
}

/*
404
*/
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.error-page-img {
  width: 600px;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.error-page-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/**
 Advices
*/
.advices-bar {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;

  @media (max-width: $sm) {
    width: 80%;
    margin: 15px auto 0 auto;
    justify-content: flex-start;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
    @media (max-width: $lg) {
      height: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 8px;
  }

  .advices-bar-item {
    width: auto;
    height: auto;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: all;
    cursor: pointer;

    text-align: center;

    /*&:nth-last-child(1) {
      .advices-bar-item-img {
        border: 1px solid #014189;
        border-radius: 100%;
        padding: 10px;
      }
    }*/

    + .advices-bar-item {
      margin-left: 15px;
    }

    &.active {
      .advices-bar-item-img {
        background-color: #fff;
      }
    }

    .advices-bar-item-img {
      display: inline-flex;
      background-size: cover;

      @media (max-width: $sm) {
        width: 55px;
        height: 55px;
      }

      width: 70px;
      height: 70px;
      border-radius: 100%;
      transition: 500ms background-color;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .advices-bar-item-text {
      width: 100%;
      text-align: center;
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.advices-container {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .advice-box {
    padding: 15px;
    color: #000;
    display: flex;
    flex-direction: column;

    .advice-box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        font-size: 25px;
        height: 30px;
        padding: 0px 20px;
        color: #afbdc3;
      }

      .advice-box-profile-img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-size: cover;
      }
    }

    .advice-box-body {
      flex: 1;
      border-radius: 10px;
      background-color: #eee;
      overflow: hidden;
      display: block;
      color: #000;

      .advice-box-img {
        width: 100%;
        height: 150px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .advice-box-desc {
        padding: 15px;
      }

      .advice-box-bar {
        text-align: right;
        padding-right: 20px;

        span {
          .btn {
            color: #004892;
          }
        }
      }
    }
  }
}

.frame-radios {
  .frame-radio {
    width: auto;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

    .frame-radio-helper {
      width: 120px;
      height: 120px;
      background-size: cover;
      display: block;
    }

    input[type="radio"] {
      margin-top: 10px;
    }
  }
}

.advertisements-container {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  .advertisements-header {
    position: relative;
    width: 100%;
    height: 450px;
    border-radius: 20px;
    margin-bottom: 100px;

    @media (max-width: $md) {
      height: 300px;
    }

    .advertisements-bar {
      position: absolute;
      bottom: -60px;
      left: 0px;
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      /*@media(max-width: $sm) {
        justify-content: flex-start;
      }*/

      z-index: 2;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 4px;
        @media (max-width: $lg) {
          height: 4px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 8px;
      }

      .advertisements-bar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        + .advertisements-bar-item {
          margin-left: 30px;
          @media (max-width: $sm) {
            margin-left: 20px;
          }
        }

        .advertisements-bar-item-img {
          //border-radius: 100%;
          width: 40px;
          height: 40px;

          @media (max-width: $sm) {
            width: 30px;
            height: 30px;
          }

          background-size: cover;
        }

        .advertisements-bar-item-text {
          margin-top: 15px;
          text-align: center;
          font-weight: 500;
          color: #000;
        }
      }
    }

    .advertisements-slider {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 0;
      @media (max-width: 550px) {
        width: 100%;
        min-width: 100%;
      }

      .slide {
        width: 100%;
        height: 100%;

        .carousel-inner {
          width: 100%;
          height: 100%;
          border-radius: 20px;

          .carousel-item {
            width: 100%;
            height: 100%;

            .carousel-img {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
    }
  }

  .advertisement-head {
    font-size: 28px;
    font-weight: 500;
    color: #004892;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 50px;
  }

  .advertisement-box {
    .advertisement-tool-btn {
      font-size: 22px;
      height: auto;
      width: 40px;
      padding: 0px;
    }

    .advertisement-box-body {
      padding: 10px;

      .advertisement-box-img {
        width: 100%;
        height: 160px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
      }

      .advertisement-box-text {
        padding: 20px 10px;
        font-weight: 500;
        color: #000;
      }

      .advertisement-box-adress {
        padding-top: 20px;
        color: #959698;
        font-weight: 500;

        i {
          font-size: 18px;
          color: #e81a46;
        }
      }
    }
  }

  .advertisements-switch {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      background-color: #fff;
      padding: 10px 40px;
      cursor: pointer;

      &.active {
        background-color: #014189;
        color: #fff;
      }
    }

    div:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    div:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.first-work-area {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .first-work-img {
    @media (max-width: $md) {
      height: 250px;
    }
    //width: 520px;
    height: 350px;
  }
}

.work-line {
  width: 100%;
  height: auto;

  .work-line-item {
    + .work-line-item {
      margin-top: -60px;
    }

    width: 100%;
    height: auto;

    &.left {
      .work-line-item-body {
        border-right: 2px solid #014189;

        .work-line-item-num {
          right: -40px;
        }
      }
    }

    &.right {
      .work-line-item-body {
        border-left: 2px solid #014189;
        transform: translate(100%);
        margin-left: -2px;

        .work-line-item-num {
          left: -40px;
        }
      }
    }

    .work-line-item-body {
      position: relative;
      width: 50%;
      padding: 20px;
      min-height: 200px;

      .work-line-item-num {
        position: absolute;
        top: 50%;
        margin-top: -40px;
        width: 80px;
        height: 80px;
        background-color: #fff;
        border: 2px solid #014189;
        color: #014189;
        font-weight: 500;
        font-size: 16px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .work-line-item-img {
        width: 100%;
        height: 200px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

.c-custom {
  color: #014189;
}

.advertisement-tab {
  .nav-pills {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 50px;
    border-radius: 20px;
    border: 2px solid #014189;
    overflow: hidden;

    .nav-item {
      flex-grow: 1;
      height: 100%;

      .nav-link {
        height: 100%;
        widows: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        color: #004892;

        &.active {
          color: #fff;
          background-color: #014189;
        }
      }
    }
  }

  .tab-content {
    background-color: #fff;
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);

    .advertisement-info-table {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;

      + .advertisement-info-table {
        border-top: 1px solid #e2e2e3;
      }

      .advertisement-info-table-value {
        font-weight: 500;
      }
    }
  }
}

.advertisement-grid {
  width: 100%;
  height: auto;
  background-color: #fafafa;
  border: 1px solid #9a9a9a;
  border-radius: 10px;
  padding: 10px;

  .btn-special-advertisement {
    background-color: #fff;
    border: 2px dashed #004892;
    color: #004892;
    font-weight: 500;
    outline: 0;
  }

  .advertisement-grid-item {
    padding: 20px;

    .advertisement-grid-item-close {
      position: absolute;
      right: 35px;
      top: 30px;
      cursor: pointer;
    }

    .advertisement-grid-item-body {
      background-color: #fff;
      width: 100%;
      padding: 20px;
      padding-top: 35px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      border-radius: 20px;

      .advertisement-grid-item-lamel {
        position: relative;
      }

      .advertisement-grid-item-lamel:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 70%;
        margin-left: 30%;
        height: 60%;
        border-left: 1px solid #9a9a9a;
        border-bottom: 1px solid #9a9a9a;
        margin-top: -1px;
      }
    }
  }
}

.alertbar {
  width: 70%;
  align-self: flex-end;
  height: auto;
  position: fixed;
  bottom: 0px;
  left: 50%;
  z-index: 999;

  .alert {
    padding: 20px;
    position: relative;
    left: -50%;
    font-weight: bold;
    margin-bottom: 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    text-align: center;
  }
}

.story-btn {
  outline: 0px;
  border: 0px;
}

.story-model {
  .modal-dialog {
    max-width: 850px;

    @media (max-width: $lg) {
      max-width: 550px;
    }

    @media (max-width: $sm) {
      height: 300px;
    }

    .modal-content {
      border: none;
      border-radius: 0;
      background: transparent;

      .modal-body {
        padding: 0;

        .close {
          display: none;
        }

        .story-model-slider {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;

          .slide {
            width: 100%;
            height: 500px;
            border-radius: 20px;
            overflow: hidden;
            @media (max-width: $lg) {
              height: 300px;
            }

            @media (max-width: $sm) {
              height: 200px;
            }

            .carousel-inner {
              width: 100%;
              height: 100%;

              .carousel-item {
                width: 100%;
                height: 100%;

                .carousel-img {
                  width: 100%;
                  height: 100%;
                  background-size: cover;
                  background-position: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.btn-custom-filter {
  color: #7d7a7a !important;
  font-weight: 500;

  i {
    font-size: 22px;
  }
}

.custom-btn {
  background-color: #004892;
  color: #fff;
}

/* platform-box */
.platform-box {
  &.platform-box-color-1 {
    .platform-box-body {
      background: rgb(75, 169, 228);
      background: -moz-linear-gradient(
        25deg,
        rgba(75, 169, 228, 1) 41%,
        rgba(167, 248, 233, 1) 100%
      );
      background: -webkit-linear-gradient(
        25deg,
        rgba(75, 169, 228, 1) 41%,
        rgba(167, 248, 233, 1) 100%
      );
      background: linear-gradient(
        25deg,
        rgba(75, 169, 228, 1) 41%,
        rgba(167, 248, 233, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4ba9e4", endColorstr="#a7f8e9", GradientType=1);
    }
  }

  &.platform-box-color-2 {
    .platform-box-body {
      background: rgb(99, 193, 115);
      background: -moz-linear-gradient(
        25deg,
        rgba(99, 193, 115, 1) 41%,
        rgba(219, 245, 152, 1) 100%
      );
      background: -webkit-linear-gradient(
        25deg,
        rgba(99, 193, 115, 1) 41%,
        rgba(219, 245, 152, 1) 100%
      );
      background: linear-gradient(
        25deg,
        rgba(99, 193, 115, 1) 41%,
        rgba(219, 245, 152, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#63c173", endColorstr="#dbf598", GradientType=1);
    }
  }

  &.platform-box-color-3 {
    .platform-box-body {
      background: rgb(223, 88, 138);
      background: -moz-linear-gradient(
        25deg,
        rgba(223, 88, 138, 1) 41%,
        rgba(254, 154, 152, 1) 100%
      );
      background: -webkit-linear-gradient(
        25deg,
        rgba(223, 88, 138, 1) 41%,
        rgba(254, 154, 152, 1) 100%
      );
      background: linear-gradient(
        25deg,
        rgba(223, 88, 138, 1) 41%,
        rgba(254, 154, 152, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#df588a", endColorstr="#fe9a98", GradientType=1);
    }
  }

  &.platform-box-color-4 {
    .platform-box-body {
      background: rgb(135, 97, 224);
      background: -moz-linear-gradient(
        25deg,
        rgba(135, 97, 224, 1) 41%,
        rgba(253, 172, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        25deg,
        rgba(135, 97, 224, 1) 41%,
        rgba(253, 172, 255, 1) 100%
      );
      background: linear-gradient(
        25deg,
        rgba(135, 97, 224, 1) 41%,
        rgba(253, 172, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8761e0", endColorstr="#fdacff", GradientType=1);
    }
  }

  height: 200px;
  padding: 25px 40px;

  .platform-box-body {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding: 5px;
    cursor: pointer;
    display: block;

    .platform-box-cont {
      width: 100%;
      background-color: #fff;
      height: 100%;
      border-radius: 35px;
      padding: 6px;

      .platform-box-cover {
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 29px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        padding: 15px 30px;

        .platform-box-img {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.multi-coursel-item {
  widows: 100%;
  height: 240px;
  padding: 20px;

  .multi-coursel-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}

.aselsan-golet-item {
  height: 260px;
  padding: 30px;

  .aselsan-golet-img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.weather_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

  .weather {
    position: relative;
    width: 1000px;
    height: auto;
    background-color: #fff;
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
    @media (max-width: 1020px) {
      width: 100%;
    }

    .weather_main {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-bottom: 1px solid #000;
      flex-wrap: wrap;

      .weather_main_group {
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
        @media (max-width: 1020px) {
          width: 100%;
          justify-content: center;
        }
        align-self: stretch;

        .weather_main_icon {
          width: 40%;
          object-fit: contain;
        }

        .weather_main_city {
          @media (max-width: $sm) {
            margin-top: -20px;
          }
          font-size: 36px;
          font-weight: 600;
          @media (max-width: $sm) {
            font-size: 20px;
          }
        }

        .weather_main_status {
          color: #004892;
          font-size: 36px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          @media (max-width: $sm) {
            font-size: 20px;
            margin-bottom: -10px;
          }

          span {
            margin: 0 5px;
          }
        }

        .weather_main_day {
          color: #004892;
          font-weight: 600;
          font-size: 18px;
          @media (max-width: 1020px) {
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }
      }
    }

    .weather_content {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .weather_content_item {
        width: 14.2%;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
        border-left: 1px solid #000;
        border-right: 1px solid #000;

        + .weather_content_item {
          margin-left: -1px;
        }

        &:first-child {
          border-left: 1px solid #000;
        }

        &:last-child {
          border-right: 1px solid #000;
        }

        .weather_content_item_day {
          width: 100%;
          color: #004892;
          font-weight: 600;
          text-align: center;
          @media (max-width: $sm) {
            font-size: 6px;
          }
        }

        .weather_content_item_icon {
          width: 100%;
          object-fit: contain;
          padding: 0 20px;
          @media (max-width: $sm) {
            padding: 0 10px;
          }
        }

        .weather_content_item_status {
          width: 100%;
          color: #004892;
          font-weight: 500;
          text-align: center;
          margin-bottom: 1em;
          @media (max-width: $sm) {
            font-size: 5px;
          }
        }

        .weather_content_item_degrees {
          margin-top: auto;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          @media (max-width: $sm) {
            padding: 0 5px;
          }

          .weather_content_item_degree {
            color: #004892;
            font-size: 14px;

            @media (max-width: $sm) {
              font-size: 4px;
              display: inline-flex;
              align-items: center;
              margin: 0 2px;

              span {
                margin: 0 !important;
              }
            }

            span {
              margin: 0 5px;
            }

            span.min {
              color: #2ed8d1;
            }

            span.max {
              color: #d00000;
            }
          }
        }
      }
    }
  }
}

.props-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .props-item {
    display: flex;
    flex-direction: row;
    margin: 20px;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .props-icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      @media (max-width: $sm) {
        width: 25px;
        height: 25px;
      }
    }

    .props-cont {
      display: flex;
      align-items: baseline;
      color: #000;

      .props-value {
        font-weight: 600;
        font-size: 22px;
        @media (max-width: $sm) {
          font-size: 12px;
        }
        margin-right: 5px;
      }

      .props-label {
        font-weight: 500;
        @media (max-width: $sm) {
          font-size: 10px;
          text-align: center;
        }
      }
    }
  }
}

.covid {
  margin: 50px 0;

  .covid-head {
    width: 100%;

    color: #004892;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;

    .covid-head-logo {
      width: 100px;
      margin: 0 15px;
      display: inline-block;
    }
  }

  .covid-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .covid-item {
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: auto;
      margin: 20px 0;

      @media (max-width: $sm) {
        margin: 20px 10px;
      }

      .covid-icon {
        background-color: #dedede;
        border-radius: 100%;
        width: 120px;
        height: 120px;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $sm) {
          width: 32px;
          height: 32px;
        }

        .covid-img {
          width: 100%;
          height: 100%;
          @media (max-width: $sm) {
            width: 32px;
            height: 32px;
          }
        }
      }

      .covid-label {
        text-align: center;
        margin-top: 10px;
        color: #004892;
        font-weight: 500;
        height: 48px;
        @media (max-width: $sm) {
          font-size: 12px;
        }
      }
    }

    .covid-mark {
      width: 40px;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      margin: 0 -20px;
      z-index: 2;
      margin-top: -48px;
      @media (max-width: $sm) {
        font-size: 25px;
      }
    }
  }

  .covid-desc {
    margin-top: 20px;
    font-size: 20px;
  }
}

.scroll-swiper {
  .scroll-swiper-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .scroll-swiper-head {
      color: #004892;
      font-size: 24px;
      font-weight: 600;
    }

    .scroll-swiper-link {
      color: #004892;
      text-decoration: none;
      border-bottom: 1px solid #004892;
      font-weight: 500;
      line-height: 1;
      padding: 0 10px;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .scroll-swiper-items {
    width: 100%;
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 10px;
    padding: 10px;
    padding-bottom: 20px;
    display: flex;

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 6px;
      @media (max-width: $lg) {
        height: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 8px;
    }

    .scroll-swiper-item {
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      min-width: 200px;
      width: 200px;
      cursor: pointer;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      user-select: none;

      + .scroll-swiper-item {
        margin-left: 30px;
      }

      .scroll-swiper-item-img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .scroll-swiper-item-circle {
        margin: 10px 0;
        width: 110px;
        height: 110px;
        border-radius: 100%;
        border: 5px solid #004892;
        object-fit: cover;
      }

      .scroll-swiper-item-label {
        white-space: normal;
        width: "100%";
        text-align: center;
        padding: 5px;
        color: #000;
      }

      .scroll-swiper-item-name {
        font-weight: 500;
        font-size: 20px;
        color: #004892;
        font-size: 18px;
      }

      .scroll-swiper-item-role {
        color: #000;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .scroll-swiper-item-view {
        color: #004892;
        margin-bottom: 10px;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
}

.aselsan-menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  .aselsan-menu-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .aselsan-menu-head {
      color: #004892;
      font-size: 24px;
      font-weight: 600;
    }

    .aselsan-menu-date {
      font-weight: 500;
    }
  }

  .aselsan-menu-item {
    width: 160px;
    min-width: 160px;
    height: auto;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin: 15px;
    border-radius: 15px;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .aselsan-menu-item-label {
      width: 100%;
      font-weight: 500;
      text-align: center;
      color: #004892;
      font-size: 20px;
    }

    .aselsan-menu-item-kcal {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;

      .aselsan-menu-item-kcal-icon {
        width: 40px;
        height: 40px;
      }

      span {
        font-weight: 500;
      }
    }
  }
}
