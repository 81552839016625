.loading{
  position: absolute;
  z-index: 200;
  color: black;
  left: -1000000px;
  top: -1000000px;

  &.is-active{
    left: 48%;
    top: 48%;
  }

}

.inner-loading{
  top: 50%;
  left: 50%;
  position: absolute;
}
