.emoji-textarea {
  position: relative;

  .form-control {
    padding-right: 2rem;
  }

  & > i {
    position: absolute;
    right: 1.3rem;
    top: 6px;
    font-size: 24px;
    color: #bababa;
    cursor: pointer;
    transition: color .3s;

    &:hover {
      color: #343434;
    }
  }

  .emoji-picker {
    opacity: 0;
    position: absolute;
    top: 2rem;
    right: -9999999rem;
    transition: opacity 0.3s;
    z-index: 10000;
  }

  .show {
    opacity: 1;
    right: 1rem;
  }

}
